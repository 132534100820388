<template>
  <div id="order-step-two" class="container order-step-two" fluid>
    <card-header-body ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="content">
        <div class="about-cancellation">
          <div class="about-cancellation__title f-w6 text-left" for="">
            キャンセルについて
          </div>
          <div class="about-cancellation__list">
            <p class="cancellation-one f-w3 text-left">
              ※予約するゴルフ場のプレー予定日6時間前から45日先までの予約Superオファーが可能
            </p>
            <div class="cancellation-two f-w3 text-left">
              <span
                >オーダーして頂きマッチング完了後のキャンセルは100％有償になります。</span
              >
              <span
                >オーダーの変更キャンセルは、Superオファーを出して、一名以上の応募があった場合でも変更、キャンセルは可能になります。</span
              >
              <span
                >応募がなかったり、応募者が満たない場合は、設定していただいた時間で自動でキャンセルされます。
              </span>
              <p>
                プレー予定日の時間前にマッチングを確定頂かなかったり、Superオファーが満たない場合は、自動でキャンセルされます。
              </p>
            </div>
            <p class="cancellation-three f-w3 text-left">
              当日の急な悪天候や、ゴルフ場のクローズによるキャンセルは、無償になりますが、当日集まってからの場合は、女性の交通費のみの支給になりますのでご注意ください。
            </p>
          </div>
        </div>
        <hr class="solid" />
        <div class="about-after-matching">
          <div class="about-after-matching__title text-left f-w6">
            マッチング後の流れについて
          </div>
          <div class="about-after-matching__list text-left f-w3">
            <p class="matching-one">
              参加キャストが確定すると、そのメンバーでメッセージ画面にトークルームが作られます。
            </p>
            <p class="matching-two">
              そちらでお客様から改めて、ゴルフ場を予約した後の「開催場所」「開催時間」「集合時間」「プレー時間」「要望」をお伝えください。
            </p>
            <p class="matching-three">
              ピックアップをご選択の際は、「集合場所」を相手と相談をして決定してください。相手の個人情報もありますので、「集合場所」にはご配慮ください。
            </p>
          </div>
        </div>
        <hr class="solid" />
        <div class="about-start-end-dissolution">
          <div class="about-start-end-dissolution__title text-left f-w6">
            開始と終了、解散について
          </div>
          <div class="about-start-end-dissolution__list text-left f-w3">
            <p class="start-end-dissolution-one">
              「現地集合」プランであれば、ゴルフ場エントランスでの合流が開始
            </p>
            <p class="start-end-dissolution-two">
              「ピックアップ」であれば、集合場所で合流した際にアプリ内の集合ボタンを押します
            </p>
            <p class="start-end-dissolution-three">
              終了は、「現地集合」の場合はプレー終了後のご精算の際。「ピックアップ」の場合は、集合した場所に戻って解散した際にキャストがアプリ内の終了ボタンを押します。
            </p>
            <p class="start-end-dissolution-four">
              ※開始終了等ありますが、解散については固定時間を導入しております。
            </p>
            <p class="start-end-dissolution-five">
              「現地集合、スループレーは4時間」「ピックアップ、スループレーは6時間」
            </p>
            <p class="start-end-dissolution-six">
              「現地集合、昼食付プレーは6時間」「ピックアップ、昼食付プレーは8時間」
            </p>
            <p class="start-end-dissolution-seven">
              時間を気にせず、安心してご利用頂けます。
            </p>
            <p class="start-end-dissolution-eight">
              ※現地集合の際は交通費を別途最大で10000p頂いております。高速代、レンタカー代、燃料代になりますので、ご了承の程、宜しくお願い致します。
            </p>
          </div>
        </div>
        <hr class="solid" />
        <div class="about-payment-auto-charged">
          <div class="about-payment-auto-charged__title text-left f-w6">
            決済はオートチャージ
          </div>
          <div class="about-payment-auto-charged__list text-left f-w3">
            <p class="payment-auto-charged-one">
              事前にポイントを購入していない場合、お支払いはご登録いただいたクレジットカードから
            </p>
            <p class="payment-auto-charged-two">
              自動的に3000P（1P=1.2円）単位でオートチャージされます。
            </p>
          </div>
          <!-- <div class="agree">
            <b-form-checkbox v-model="status" class="f-w3 check-box">
              同意する
            </b-form-checkbox>
          </div> -->
          <button @click="gotoConfirm" class="btn-submit f-w3">同意する</button>
        </div>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import CardHeader from "../../components/CardHeader";
import CardFooter from "@/components/CardFooter";
import { mapGetters } from "vuex";
import { SYSTEM_ROOM_TYPE } from "@/constants/message";

export default {
  name: "OrderStepTwo",
  props: {
    isPrevious: {
      type: Boolean
    },
    profileId: {
      type: [String, Number]
    }
  },
  components: {
    "card-header-body": CardHeader,
    CardFooter
  },
  metaInfo() {
    return {
      title: "初回利用の方へ",
      titleTemplate: "%s | PreGo"
    };
  },
  // computed: {
  //   ...mapGetters({
  //     user: "auth/user",
  //     searchOrder: "orderManagement/searchOrder",
  //     commonData: "common/commonTutorials",
  //     isLoading: "common/isLoading",
  //     cardInfo: "payment-stripe/cardInfo"
  //   }),
  data() {
    return {
      status: false,
      headerTitle: {
        text: "初回利用の方へ",
        isShowTitle: true,
        isShowLogo: false
      }
    };
  },
  methods: {
    async createOrder() {
      // this.$refs["modal-confirm-create-order"].closeModal();
      this.$root.$refs.loading.start();
      await this.$store.dispatch("payment-stripe/getCreditCard");
      const creditCard = this.$store.getters["payment-stripe/cardInfo"];
      if (!creditCard) {
        // from create new normal order
        let castId = localStorage.getItem("direct_order");
        if (castId) {
          this.$router.push({
            name: "MenRegisterCardCredit",
            params: { castId: castId }
          });
        } else {
          this.$router.push({
            name: "MenRegisterCardCredit",
            params: { profileId: this.searchOrder.profileId }
          });
        }
        return;
      }
      let params = {
        ...this.searchOrder,
        user_id: this.user.user_id,
        golf_course: this.searchOrder.golf_course_new,
        female_golf_rank_ids: this.searchOrder.female_golf_ranks,
        female_prego_rank_ids: this.searchOrder.female_prego_ranks,
        scheduled_meeting_datetime: this.$dayjs(
          this.searchOrder.scheduled_meeting_datetime
        ).format("YYYY-MM-DD HH:mm:00")
      };
      if (this.searchOrder.profileId) {
        params = {
          ...params,
          designation_user_id: Number(this.searchOrder.profileId)
        };
      }
      let url = "orderManagement/createOrder";
      let castId = localStorage.getItem("direct_order");
      if (this.$route.params.id) {
        url = "orderManagement/editOrder";
        params = {
          ...params,
          ad_id: this.$route.params.id
        };
        this.headerTitle.backTo = "/my-page/orders";
      } else if (castId) {
        // create direct order success: click button back => go to cast profile
        this.headerTitle.backTo = `/profile/${castId}`;
      } else {
        this.headerTitle.backTo = "/create-order";
      }

      delete params.female_golf_ranks;
      delete params.female_prego_ranks;

      this.$store
        .dispatch(url, params)
        .then(response => {
          this.$root.$refs.loading.finish();
          let data = {
            hiddenUserIds: {
              [this.user.user_id]: true,
              [0]: true
            },
            userIds: [this.user.user_id, 0],
            adId: null,
            type: SYSTEM_ROOM_TYPE
          };
          this.$store
            .dispatch("firebase/createRoom", data)
            .then(result => {
              let dataMessage = {
                roomId: result,
                readUsers: {
                  [0]: true,
                  [this.user.user_id]: false
                },
                message: {
                  golf_area: this.getAreaName(),
                  scheduled_meeting_datetime: params.scheduled_meeting_datetime,
                  participants:
                    params.male_participants + params.female_participants,
                  url: `/create-order/order-detail/${response.data.ad_id}`
                }
              };
              this.$store.dispatch("firebase/createSystemMessage", dataMessage);
            })
            .catch(error => {
              throw error;
            });
          if (response?.message) {
            this.$toast(response?.message, "通知", "danger");
            return;
          }
          // let params = {
          //   is_previous: true,
          // };
          if (this.$route.params.id) {
            this.$router.push({
              name: "MenEditConfirmOrder",
              params: {
                id: this.$route.params.id,
                isPrevious: true
              },
              query: {
                create: "success"
              }
            });
          } else {
            this.$router.push({
              name: "MenCreateConfirmOrder",
              params: {
                isPrevious: true
              },
              query: {
                create: "success"
              }
            });
          }
          this.$toast("Superオファーを作成しました。", "通知", "success");
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    getAreaName() {
      return this.searchOrder.golf_area_id
        ? this.commonData.golfAreas &&
            this.commonData.golfAreas?.find(
              el => el.golf_area_id === this.searchOrder.golf_area_id
            ).name
        : "";
    },
    gotoConfirm() {
      let params = {
        isPrevious: true
      };
      if (this.profileId) {
        params.profileId = this.profileId;
      }
      if (this.$route.params.id) {
        this.$router.push({
          name: "MenEditConfirmOrder",
          params: {
            id: this.$route.params.id,
            ...params
          }
        });
      } else {
        this.$router.push({
          name: "MenCreateConfirmOrder",
          params
        });
      }
    }
  },
  mounted() {
    document.getElementById("order-step-two")?.scrollIntoView(true);
  },
  computed: {
    ...mapGetters({
      // data: "orderManagement/searchOrder",
      user: "auth/user",
      searchOrder: "orderManagement/searchOrder",
      commonData: "common/commonTutorials",
      isLoading: "common/isLoading",
      cardInfo: "payment-stripe/cardInfo"
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "MenCreateOrderStepOne") {
        localStorage.removeItem("direct_order");
      }
      if (
        from.name === "MenCreateConfirmOrder" ||
        from.name === "MenEditConfirmOrder"
      ) {
        next();
      } else if (from.name === null) {
        vm.$router.go("-1");
      } else if (!from.name.includes("CreateOrder") || vm.searchOrder.isSave) {
        vm.$store.dispatch("orderManagement/resetOrder");
        if (!vm.$route.params.id) {
          vm.$router.push({
            name: "MenCreateOrderStepOne"
          });
        }
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/men/orderStepTwo.scss";

.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    // height: calc(100vh - 152px);
    padding-bottom: 100px;
  }
}
</style>
